import React, { useContext } from 'react';
import { TelefoniaContext } from '../context';
import {  Redirect } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import "../css/Login.css";
import { useEffect } from 'react';

const Login = () => {
  const {
    email,
    password,
    setEmail,
    setPassword,
    handleClickLogin,
    invalidUser,
    redirectLogin,
  } = useContext(TelefoniaContext);

  useEffect(() => {
    setEmail('');
    setPassword('');
  }, []);

  return (
    <div className="login">
      <div className="login-field">
        <div className="login-div">
          <div
             style={{ marginBottom: 50, display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <PersonIcon
              style={{ fontSize: 150, marginBottom: 10, color: "#62337F" }}
            />
            <span style={ { fontSize:25, fontWeight: 500, color: "#4a2263" } }>Login</span>
            {
              invalidUser
              && <span style={ { fontSize: 16, fontWeight: 500, color: "#c9061a", marginTop: 10 } }>Usuário ou Senha inválido</span>
            }
          </div>
            <TextField
              style={ { width: "50%", marginBottom: 10 } }
              label="Email"
              type="text"
              name="email"
              id="email"
              value={ email }
              onChange={ ({ target }) => setEmail(target.value) } 
            />
            <TextField
              style={ { width: "50%" } }
              label="Senha"
              type="password"
              name="password"
              id="password"
              value={ password }
              onChange={ ({ target }) => setPassword(target.value) } 
            />
          <Button
            style={ { backgroundColor: "#62337F", color: "white", marginTop: 15 } }
            variant="contained"
            onClick={ handleClickLogin }
          >
            Entrar
          </Button>
         { redirectLogin && <Redirect to= "/painel"  /> }
        </div> 
      </div>
    </div>
  );
}

export default Login
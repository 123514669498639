import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { TelefoniaContext } from '../context';
import '../css/SideNav.css';

const SideNav = () => {
  const {
    fetchToLogout,
  } = useContext(TelefoniaContext);

  const [checked, setChecked] = useState(false);
  const [classTransition, setClassTransition] = useState('barra');
  const [user, setUser] = useState();

  useEffect(() => {
    if (checked) {
      setClassTransition('barra transition');
    } else {
      setClassTransition('barra');
    }
  }, [checked]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')));
  }, []);

  return (
    <div>
      <nav className="nav">
          <label id="icone" htmlFor="check">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            <input
              id="check"
              type="checkbox"
              className={checked ? 'transition' : ''}
              onClick={() => setChecked((prev) => !prev)}
            />
          </label>
          <div>
          <span className="username"> { user !== undefined && user.username }</span>
            <Button
                variant="light"
                style={ { marginRight: 30 } }
                onClick={ fetchToLogout }
            >
              Sair
              </Button>
                <img
                    className="athene-img"
                    src="https://athenetec.com/wp-content/uploads/2020/06/logo_branca.png"
                    alt="icone de uma coruja"
                />
          </div>

      </nav>
      <div className={classTransition}>
            <nav>
              <Link to="/painel"><div className="link">Gerenciar Filas</div></Link>
              <Link to="/emergencia"><div className="link">Gerenciar Fila de Emergencia</div></Link>
              <Link to="/FilaErro"><div className="link">Gerenciar Fila De Erro</div></Link>
              <Link to="/statusFilas"><div className="link">Ver Status das Filas</div></Link>
            </nav>
        </div>
      </div>
  )
};

export default SideNav;
import React, { useContext } from 'react';
import { TelefoniaContext } from '../context';
import Card from 'react-bootstrap/Card';

const CardDetails = ({ result }) => {
  const {
    collectionName,
  } = useContext(TelefoniaContext);
  return (
    <Card key={result._id} style={{ width: '25rem', marginRight: 20 }} id={result._id}>
    <Card.Body>
      <Card.Title>{result.nome}</Card.Title>
      <Card.Subtitle className="mb-2 text-muted capitalized">{collectionName === 'manutencao' ? 'Suporte' : collectionName}</Card.Subtitle>
      <Card.Text>
        <strong>Id da Fila:</strong> {result.idFilaRamal} <br />
        <strong> Ramal:</strong>{result.ramal} <br />
        <strong>Atendimentos:</strong> { result.atendimentos } <br />
        <strong>Url Do Google Chat:</strong> { result.urlGoogleChat } <br />
        <strong>Id Do Google Chat:</strong> { result.idGoogleChat } <br />
        <strong>OffSet de Atendimentos:</strong> { result.offset_atendimentos !== undefined ? result.offset_atendimentos : '' }<br />
        <strong>OffSet da Quantidade de Leads:</strong> { result.offset_qntleads !== undefined ? result.offset_qntleads : '' }<br />
        <strong>Quantidade de Leads:</strong> { result.quantidade_leads !== undefined ? result.quantidade_leads : '' }<br />
        <br/><span style={ { marginTop: 10 } }>Horário de Trabalho</span>
        { result.horarioTrabalho !== undefined && result.horarioTrabalho.length >0 
        &&<ul>
          <li>Segunda-Feira: <br/>
            Início: { `${ result.horarioTrabalho[1].horaDeInicioTrabalho }:${ result.horarioTrabalho[1].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[1].horaDeInicioIntervalo }:${ result.horarioTrabalho[1].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[1].horaDeFimIntervalo }:${ result.horarioTrabalho[1].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[1].horaDeFimTrabalho }:${ result.horarioTrabalho[1].minutoDeFimTrabalho }` }
          </li>
          <li>Terça-Feira: <br/>
            Início: { `${ result.horarioTrabalho[2].horaDeInicioTrabalho }:${ result.horarioTrabalho[2].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[2].horaDeInicioIntervalo }:${ result.horarioTrabalho[2].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[2].horaDeFimIntervalo }:${ result.horarioTrabalho[2].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[2].horaDeFimTrabalho }:${ result.horarioTrabalho[2].minutoDeFimTrabalho }` }
          </li>
          <li>Quarta-Feira: <br/>
            Início: { `${ result.horarioTrabalho[3].horaDeInicioTrabalho }:${ result.horarioTrabalho[3].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[3].horaDeInicioIntervalo }:${ result.horarioTrabalho[3].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[3].horaDeFimIntervalo }:${ result.horarioTrabalho[3].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[3].horaDeFimTrabalho }:${ result.horarioTrabalho[3].minutoDeFimTrabalho }` }
          </li>
          <li>Quinta-Feira: <br/>
            Início: { `${ result.horarioTrabalho[4].horaDeInicioTrabalho }:${ result.horarioTrabalho[4].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[4].horaDeInicioIntervalo }:${ result.horarioTrabalho[4].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[4].horaDeFimIntervalo }:${ result.horarioTrabalho[4].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[4].horaDeFimTrabalho }:${ result.horarioTrabalho[4].minutoDeFimTrabalho }` }
          </li>
          <li>Sexta-Feira: <br/>
            Início: { `${ result.horarioTrabalho[5].horaDeInicioTrabalho }:${ result.horarioTrabalho[5].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[5].horaDeInicioIntervalo }:${ result.horarioTrabalho[5].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[5].horaDeFimIntervalo }:${ result.horarioTrabalho[5].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[5].horaDeFimTrabalho }:${ result.horarioTrabalho[5].minutoDeFimTrabalho }` }
          </li>
          <li>Sábado: <br/>
            Início: { `${ result.horarioTrabalho[6].horaDeInicioTrabalho }:${ result.horarioTrabalho[6].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[6].horaDeInicioIntervalo }:${ result.horarioTrabalho[6].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[6].horaDeFimIntervalo }:${ result.horarioTrabalho[6].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[6].horaDeFimTrabalho }:${ result.horarioTrabalho[6].minutoDeFimTrabalho }` }
          </li>
          <li>Domingo: <br/>
            Início: { `${ result.horarioTrabalho[0].horaDeInicioTrabalho }:${ result.horarioTrabalho[0].minutoDeInicioTrabalho }` } 
            <br/>Intervalo:  { `${ result.horarioTrabalho[0].horaDeInicioIntervalo }:${ result.horarioTrabalho[0].minutoDeInicioIntervalo }` }  -  { `${ result.horarioTrabalho[0].horaDeFimIntervalo }:${ result.horarioTrabalho[0].minutoDeFimIntervalo }` }
            <br/>Fim: { `${ result.horarioTrabalho[0].horaDeFimTrabalho }:${ result.horarioTrabalho[0].minutoDeFimTrabalho }` }
          </li>
        </ul>}
      </Card.Text>
    </Card.Body>
  </Card>
  );
}

export default CardDetails;
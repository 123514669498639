import React, { createContext, useState } from 'react';

export const EmergenciaContext = createContext();

export const EmergenciaProvider = ({ children }) => {
  const filasStatus = ['consultoria', 'representantes', 'financeiro', 'manutencao', 'representantesPJ', 'parceirosFornecedores'];
  const [filas, setFilas] = useState();
  const [allFilas, setAllFilas] = useState();
  const [ramais, setRamais] = useState();
  const [selectedRamal, setSelectedRamal] = useState('62212');
  const [showModal, setShowModal] = useState();
  const [idToEdit, setIdFilaToEdit] = useState('');
  const [redirect, setRedirect] = useState('');
  const [selectedFila, setSelectedFila] = useState(filasStatus[0]);

  const endpoint2 = `${process.env.REACT_APP_DOMINIO_SERVIDOR}/getRamaisEmergencia`;
  const endpointFilas = `${process.env.REACT_APP_DOMINIO_SERVIDOR}/getStatusFilas`;

  const fetchToConfirmLogin  = () => {
    let Bearer = `Bearer ${localStorage.getItem('Token')}`;
    let myHeaders = {
    // 'Accept': 'application/json',
    'Content-Type': 'application/json',
    'mode': 'no-cors',
    'authorization': Bearer,
  };
  fetch(`${process.env.REACT_APP_API_TELEFONIA}/front/verificaToken`, {
    headers: myHeaders,
    method: 'GET',
  })
    .then((res) => res.json())
    .then((obj) => {
      if (localStorage.getItem('Token') !== obj.token) {
        window.location.href =  process.env.REACT_APP_DOMINIO;
      }
    })
  }

  const getFilas = (endpoint, method) => {
    fetch(endpoint, { method, headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((obj) => {
      setFilas(obj);
    });
  }

  const getAllFilas = () => {
    const body = { selectedFila, }
    setAllFilas(undefined);
    fetch(endpointFilas, { method: 'post', headers: { 'Content-Type': 'application/json' },  body: JSON.stringify(body) })
    .then((response) => response.json())
    .then((obj) => {
      setAllFilas(obj);
    });
  }

  const deleteRamalFromFila = (ramal) => {
    const zenviaEndpointDeleteRamal = `https://voice-api.zenvia.com/fila/${ idToEdit }/${ ramal }`;
    fetch(zenviaEndpointDeleteRamal, { method: 'delete', headers: { 'Content-Type': 'application/json', 'Access-Token': '677d7d3542279cabb11288e0b14321d6' } })
    .then((response) => response.json())
    .then((obj) => {
      addRamalInFila();

    });
  }

  const addRamalInFila = () => {
    const zenviaEndpointAddRamal = `https://voice-api.zenvia.com/fila/${ idToEdit }`;
    fetch(zenviaEndpointAddRamal, { method: 'post', headers: { 'Content-Type': 'application/json', 'Access-Token': '677d7d3542279cabb11288e0b14321d6' }, body:JSON.stringify({ ramal_id: selectedRamal }) })
    .then((response) => response.json())
    .then((obj) => {
      setRedirect(true)
    });

  }

  const getRamais = () => {
    fetch(endpoint2, { method: 'post', headers: { 'Content-Type': 'application/json' } })
    .then((response) => response.json())
    .then((obj) => {
      setRamais(obj);
      setRedirect(false);
    });
  }


  const handleClick = () => {
    const currentRamal = filas.find(e => e.dados.id.toString() === idToEdit );
    if (currentRamal.dados.ramais.length === 0) {
      addRamalInFila();
    } else {
      deleteRamalFromFila(currentRamal.dados.ramais[0].id);
    }
  };

  const handleClickModal = ({ target }) => {
    const { id } = target;
    setShowModal(true)
    setIdFilaToEdit(id);
  }

  const contextValue = {
   getFilas,
   filas,
   getRamais,
   setShowModal,
   showModal,
   ramais,
   selectedRamal,
   setSelectedRamal,
   handleClick,
   handleClickModal,
   redirect,
   allFilas,
   getAllFilas,
   filasStatus,
   selectedFila,
   setSelectedFila,
   setAllFilas,
   fetchToConfirmLogin,
  };

  return (
    <EmergenciaContext.Provider value={contextValue}>
      { children}
    </EmergenciaContext.Provider>
  );
}
import React, { useContext } from 'react';
import { TelefoniaContext } from '../context';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import "../css/Input.css";

const FormHorarioTrabalho = () => {
  const {
    weekDay,
    horaDeFimIntervalo,
    setHoraDeFimIntervalo,
    horaDeFimTrabalho,
    setHoraDeFimTrabalho,
    horaDeInicioIntervalo,
    setHoraDeInicioIntervalo,
    horaDeInicioTrabalho,
    minutoDeFimIntervalo,
    setMinutoDeFimIntervalo,
    minutoDeFimTrabalho,
    setMinutoDeFimTrabalho,
    minutoDeInicioIntervalo,
    setMinutoDeInicioIntervalo,
    minutoDeInicioTrabalho,
    setMinutoDeInicioTrabalho,
    setHoraDeInicioTrabalho,
    nextDayHorarioTrabalho
  } = useContext(TelefoniaContext);
  const diasDaSemana = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
  const horasDoDia = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  const minutosDoDia = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];

  useEffect(() => {
    return function cleanStorage() {
      localStorage.setItem('horarioDeTrabalho', JSON.stringify([]));
    }
  },[]);

  return (
    <div className="form-horario-trabalho">
      <p>Horário de Trabalho</p>
      <p>Dia Da Semana :<strong> { diasDaSemana[weekDay] } </strong></p>
      <div className="div-checkbox">
        <label>Hora de Início
          <select value={ horaDeInicioTrabalho } onChange={ ({ target }) => setHoraDeInicioTrabalho(parseInt(target.value)) }>
            { horasDoDia.map((e, index) => (
              <option key={ index } value={ index }>{ e }</option>
            )) }
          </select>
        </label>
        <label>Minuto de Inicio
          <select value={ minutoDeInicioTrabalho } onChange={ ({ target }) => setMinutoDeInicioTrabalho(parseInt(target.value)) }>
            { minutosDoDia.map((e, index) => (
              <option key={ index } value={ index }>{ e }</option>
            )) }
          </select>  
          </label>
      </div>
      <div className="div-checkbox">
        <label>Hora de Intervalo
          <select value={ horaDeInicioIntervalo } onChange={ ({ target }) => setHoraDeInicioIntervalo(parseInt(target.value)) }>
            { horasDoDia.map((e, index) => (
              <option key={ index } value={ index }>{ e }</option>
            )) }
          </select>
        </label>
        <label>Minuto de Intervalo
          <select value={ minutoDeInicioIntervalo } onChange={ ({ target }) => setMinutoDeInicioIntervalo(parseInt(target.value)) }>
            { minutosDoDia.map((e, index) => (
              <option key={ index } value={ index }>{ e }</option>
            )) }
          </select>
        </label>
      </div>
      <div className="div-checkbox">
        <label>Hora de Fim de Intervalo
          <select value={ horaDeFimIntervalo } onChange={ ({ target }) => setHoraDeFimIntervalo(parseInt(target.value)) }>
            { horasDoDia.map((e, index) => (
              <option key={ index } value={ index }>{ e }</option>
            )) }
          </select>
        </label>
        <label>Minuto de Fim de Intervalo
          <select value={ minutoDeFimIntervalo } onChange={ ({ target }) => setMinutoDeFimIntervalo(parseInt(target.value)) }>
            {minutosDoDia.map((e, index) => (
              <option key={ index } value={index}>{ e }</option>
            ))}
          </select>
        </label>
      </div>
      <div className="div-checkbox">
        <label>Hora de Saída
            <select value={ horaDeFimTrabalho } onChange={({ target }) => setHoraDeFimTrabalho(parseInt(target.value)) }>
              {horasDoDia.map((e, index) => (
                <option key={index} value={index}>{e}</option>
              ))}
            </select>
          </label>
          <label>Minuto de Saída
            <select value={ minutoDeFimTrabalho } onChange={({ target }) => setMinutoDeFimTrabalho(parseInt(target.value)) }>
              { minutosDoDia.map((e, index) => (
                <option key={ index } value={ index }>{ e }</option>
              )) }
            </select>
          </label>
      </div>
        <Button variant="info" style={{ marginLeft: 30, marginBottom: 20, width: 120 }} onClick={ nextDayHorarioTrabalho }>Próximo</Button>
    </div>
  );
}

      export default FormHorarioTrabalho;
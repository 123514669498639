import React, { useContext, useEffect, useState } from 'react';
import { TelefoniaContext } from '../context';
import FormInput from '../components/FormInput';
import SideNav from '../components/SideNav';
import { Redirect } from 'react-router-dom';
import CardDetails from '../components/CardDetails';

import "../App.css"

const Edit = ({ match }) => {
  const {
    colaboradores,
    fetchSearch,
    collectionName,
    setIdToDelete,
    setIdToAdd,
    redirect,
    setCollectionName
  } = useContext(TelefoniaContext);

  const [result, setResult ] = useState();

  const renderForm = () => {
    if (colaboradores !== undefined && colaboradores.length > 0) {
    return (
    <FormInput type="edit" collectionName={ collectionName }/>
    );
  }
}
  useEffect(() => {
    fetchSearch(match.params.collection);
    setCollectionName(match.params.collection);
    setIdToDelete(match.params.id);
    setIdToAdd(match.params.id)
  }, []);

  useEffect(() => {
    if (colaboradores !== undefined) {
      const colaborador = colaboradores.find(e => e._id === match.params.id);
      setResult(colaborador)
      localStorage.setItem('horarioDeTrabalho', JSON.stringify(colaborador.horarioTrabalho));
    }
  }, [colaboradores]);

  return (
    <div>
      <SideNav />
      <div className="edit">
        <span className="title">Editar</span>
        <div className="edit-field">
          <div className="edit-form">
          { colaboradores !== undefined && renderForm() }
          </div>
          { result !== undefined && <CardDetails result={ result } /> }
        </div>
        { redirect && <Redirect to= "/painel"  /> }
      </div>
    </div>
  )
};

export default Edit;

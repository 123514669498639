import React, { useContext } from 'react';
import { TelefoniaContext } from '../context';
import Button from 'react-bootstrap/Button';
import FormHorarioTrabalho from './FormHorarioTrabalho';
import Form from 'react-bootstrap/Form';
import "../css/Input.css";

const FormInput = ({ type, collectionName }) => {
  const {
    handleChange,
    idFila,
    nome,
    ramal,
    url,
    handleClickEdit,
    atendimentos,
    offSet,
    qtdLeads,
    handleClickInsert,
    disableButton,
    checkHorarioEdit,
    setCheckHorarioEdit,
    setDisableEditOption,
    disableEditOption,
    googleChatId
  } = useContext(TelefoniaContext);

  const clickEditHorario = () => {
    setCheckHorarioEdit((prev) => !prev);
    setDisableEditOption(true);
    localStorage.setItem('horarioDeTrabalho', JSON.stringify([]));
  }

  const renderHorarioForm = () => (
    <Form.Group style={ { marginTop: 30 } } controlId="validationFormik01">
      <label> Editar Horário de Trabalho
          <Form.Check
            type="checkbox"
            disabled = { disableEditOption }
            value={ checkHorarioEdit }
            checked={ checkHorarioEdit }
            onClick={ clickEditHorario }
          />
      </label>
        { checkHorarioEdit && <FormHorarioTrabalho /> }
    </Form.Group>
  );

  const renderForm = () => (
      <div>
        <label className="label">Nome
          <Form.Control
            className="input-full-screen"
            type="text"
            name="nome"
            id="nome"
            value={ nome }
            onChange={ handleChange }
          />
        </label> 

        <label className="label">Url Do Google Chat
          <Form.Control
            className="input-full-screen"
            type="text"
            name="urlGoogleChat"
            id="urlGoogleChat"
            value={ url }
            onChange={ handleChange }
          />
        </label>

        <label className="label">Id do Google Chat
          <Form.Control
            className="input-full-screen"
            type="text"
            name="idGoogleChat"
            id="idGoogleChat"
            value={ googleChatId }
            onChange={ handleChange }
          />
        </label>  
       
        <label className="label">Ramal
          <Form.Control
            className="input-mediu-screen"
            type="text"
            name="ramal"
            id="ramal"
            value={ ramal }
            onChange={ handleChange }
          />
        </label>  

        <label className="label">Id Fila Do Ramal
          <Form.Control
            className="input-mediu-screen"
            type="text"
            name="idFilaRamal"
            id="idFilaRamal"
            value={ idFila }
            onChange={ handleChange }
          />
        </label>  
      </div>
  )

  if (type === 'insert' || collectionName === 'colaboradores') {
    return ( <div>
      { renderForm() }
      { type === "insert" && <FormHorarioTrabalho /> }
      { type === "edit" && renderHorarioForm() }
      <Button
          type="button"
          variant="success"
          onClick={ type === 'edit' ? handleClickEdit : handleClickInsert }
          // disable={ type === 'insert' ? disableButton : false }
          disabled={ type === 'edit' ? (checkHorarioEdit ? disableButton : false) : disableButton }
        >
          Adicionar
        </Button>  
    </div>
    );
  } else {
    if (collectionName === 'representantes') {
      return (
        <div>
          { renderForm() }

          <label className="label">Número de Atendimentos
            <Form.Control
              className="input-mediu-screen"
              type="text"
              name="atendimentos"
              id="atendimentos"
              value={ atendimentos }
              onChange={ handleChange }
            />
          </label>  
         
          <label className="label">OffSet da Quantidade de Leads
            <Form.Control
              className="input-mediu-screen"
              type="text"
              name="offSet"
              id="offSet"
              value={ offSet }
              onChange={ handleChange }
            />
          </label>  

          <label className="label">Quantidade de Leads
            <Form.Control
              className="input-mediu-screen"
              type="text"
              name="qtdLeads"
              id="qtdLeads"
              value={ qtdLeads }
              onChange={ handleChange }
            />
          </label>  

          { renderHorarioForm() }

          <Button
          type="button"
          variante="primary"
          disabled={ checkHorarioEdit ? disableButton : false }
          onClick={ handleClickEdit }
        >
          Adicionar
        </Button>
        </div>
      );
    } else {
      return (
        <div>
          { renderForm() }

          <label className="label">Número de Atendimentos
            <Form.Control
              className="input-mediu-screen"
              type="text"
              name="atendimentos"
              id="atendimentos"
              value={ atendimentos }
              onChange={ handleChange }
            />
          </label>  

          <label className="label">OffSet de Atendimentos
            <Form.Control
              className="input-mediu-screen"
              type="text"
              name="offSet"
              id="offSet"
              value={ offSet }
              onChange={ handleChange }
            />
          </label>  
        
        { renderHorarioForm() }

        <Button
          type="button"
          variante="primary"
          disabled={ checkHorarioEdit ? disableButton : false }
          onClick={ handleClickEdit  }
        >
          Adicionar
        </Button>         
        </div>
      );
    } 
  }
}

export default FormInput;
import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { EmergenciaContext } from '../context/EmergenciaContext.js';
import Button from 'react-bootstrap/Button';
import SideNav from './SideNav.jsx';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import "../App.css";

const EmergenciaTable = ({ location }) => {
  const {
    filas,
    getFilas,
    getRamais,
    setShowModal,
    showModal,
    handleClick,
    ramais,
    selectedRamal,
    setSelectedRamal,
    handleClickModal,
    redirect,
    fetchToConfirmLogin
  } = useContext(EmergenciaContext);

  const [page, setPage] = useState('');
  async function verificaLogin(){
    await fetchToConfirmLogin();
  };

  useEffect(() => {
    const { pathname } = location;
    const endpoint1 = `${process.env.REACT_APP_DOMINIO_SERVIDOR}/getFilasEmergencial`;
    const endpointFilaErro =  `${process.env.REACT_APP_DOMINIO_SERVIDOR}/getFilasErro`;
    if (pathname.includes("FilaErro")) {
      getFilas(endpointFilaErro, 'get');
      setPage('FilaErro');
    } else {
      getFilas(endpoint1, 'post');
    }
    verificaLogin();
    getRamais();
  }, []);

  useEffect(() => {
  }, [filas])

  const renderSelect = () => (
    <Form.Group controlId="validationFormik01">
      <Form.Label>
        Selecione o Ramal
        <Form.Control as="select" value={ selectedRamal } onChange={({ target }) => setSelectedRamal(target.value)}>
          {ramais !== undefined && ramais.fila.map((e) => (
            <option key={ e.id } value={ e.id }>{ `${ e.ramal } - ${ e.login }` }</option>
          ))}
        </Form.Control>
      </Form.Label> 
    </Form.Group>
  )

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Mudar Ramal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { renderSelect() }
         <Form.Group>
          <Button
              variant="info"
              onClick={ handleClick }
              style={ { marginLeft: 10} }
            >
              Trocar
            </Button>
         </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

  const renderTable = () => (
    <div className='table-div'>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Id</th>
            <th>Nome</th>
            <th>Ramal</th>
            <th>Status Do Ramal</th>
            <th>Login Do Ramal</th>
            <th>Trocar Ramal</th>
          </tr>
        </thead>
        <tbody>
            { filas.map((fila) => (
              <tr key={ fila.dados.id }>
                <td>{ fila.dados.id }</td>
                <td>{ fila.dados.nome }</td>
                <td>{ fila.dados.ramais.length === 0 ? '-' : fila.dados.ramais[0].ramal }</td>
                <td>{ fila.dados.ramais.length === 0 ? '-' : fila.dados.ramais[0].status }</td>
                <td>{ fila.dados.ramais.length === 0 ? '-' : fila.dados.ramais[0].login }</td>
                <td>
                  <Button
                    id={  fila.dados.id }
                    onClick={ handleClickModal }
                  >
                    Editar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
      </Table>
    </div>
  );

  const renderLoading = () => (
    <div>
      <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
    </div>
  );

  const redirectTo = () => window.location.reload();
  return (
    <div className="table-emergencia">
      <SideNav />
      <div className="emergencia-fila">
        <span className="title">{ page === 'FilaErro'
          ? 'Gerenciamento Da Fila de Erro'
          : 'Fila Da Central de Emergencia' }
        </span>
        {/* { filas !== undefined && renderTable() }  */}
        { filas === undefined ? renderLoading() : renderTable() }
      </div>
      <MyVerticallyCenteredModal
        show={ showModal }
        onHide={() => setShowModal(false)}
      />
    { redirect && redirectTo() }
    </div>
  );
}

export default EmergenciaTable
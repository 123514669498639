import React, { useEffect, useContext } from 'react';
import { EmergenciaContext } from '../context/EmergenciaContext';
import SideNav from './SideNav.jsx';
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import "../App.css";

const TableStatusFilas = () => {
  const {
    allFilas,
    getAllFilas,
    filasStatus,
    selectedFila,
    setSelectedFila,
    fetchToConfirmLogin
  } = useContext(EmergenciaContext);

  async function verificaLogin(){
    await fetchToConfirmLogin();
  };

  useEffect(() => {
    verificaLogin();
    getAllFilas();
  }, [selectedFila]);

  const renderLoading = () => (
    <div class="loading">
      <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
    </div>
  );

 const renderTable = () => {
  return (
    <Table striped bordered hover style={ { width: "70%", margin: "auto" } }>
      <thead>
        <tr>
          <th>Id</th>
          <th>Nome</th>
          <th>Ramal</th>
          <th>Status Do Ramal</th>
          <th>Login Do Ramal</th>
        </tr>
      </thead>
      <tbody>
        { allFilas.map((fila) => (
          <tr key={ fila.dados.id }>
            <td>{ fila.dados.id }</td>
            <td>{ fila.dados.nome }</td>
            <td>{ fila.dados.ramais.length === 0 ? '-' : fila.dados.ramais[0].ramal }</td>
            <td>{ fila.dados.ramais.length === 0 ? '-' : fila.dados.ramais[0].status }</td>
              <td>{ fila.dados.ramais.length === 0 ? '-' : fila.dados.ramais[0].login }</td>
            </tr>
        ))}
        </tbody>
    </Table>
    );
   }
  const renderSelect = () => (
    <Form.Group controlId="validationFormik01">
      <Form.Label>
        Selecione a Fila que deseja ver o Status
        <Form.Control as="select" value={ selectedFila } disabled={ allFilas === undefined ? true : false } onChange={({ target }) => setSelectedFila(target.value)}>
          {filasStatus !== undefined && filasStatus.map((e) => (
            <option className="capitalized" key={ e } value={ e }>{ e === 'manutencao' ? 'Suporte' : e }</option>
          ))}
        </Form.Control>
      </Form.Label> 
    </Form.Group>
  )

  return (
  <div>
    <div className="table-Filas">
        <SideNav />
      <div className="fila">
        <span className="title">Status das Filas</span>
        {/* { filas !== undefined && renderTable() }  */}
        { renderSelect() }
      </div>
    { allFilas === undefined ? renderLoading() : renderTable() }
    </div>
  </div>
  );
}

export default TableStatusFilas;
import React from 'react';
import { EmergenciaProvider } from '../context/EmergenciaContext.js';
import TableStatusFilas from '../components/TableStatusFilas.jsx';

const StatusFilas = () => {

  return (
  <EmergenciaProvider>
    <TableStatusFilas />
  </EmergenciaProvider>
  );
}

export default StatusFilas;
import React from 'react';
import { EmergenciaProvider } from '../context/EmergenciaContext.js';
import EmergenciaTable from '../components/EmergenciaTable.jsx';

const Emergencia = ({ location }) => {  
  return (
    <EmergenciaProvider>
      <EmergenciaTable location={ location } />
    </EmergenciaProvider>
  );
}

export default Emergencia;